<script setup lang="ts">
const { isDesktop, isIos } = useDevice();
const title = useState("pageTitle", () => "FLIK");
const keywords = useState("pageKeywords", () => "flik, checkout, nuxt");
const route = useRoute();
const trx = useTransactionStore();

const cond1 = computed(() => {
  if (
    ["PENDING", "SUCCESS", "PAID", "CAPTURED", "SUCCEEDED"].includes(
      trx.lastTrxStatus,
    )
  )
    return true;
});

console.log("route:", route);
const rName: any = route.name || "";
console.log("rName:", rName);
if (hasLength(rName)) title.value = rName.toUpperCase();
</script>
<template>
  <Head>
    <Title>{{ title }}</Title>
    <Meta name="description" :content="title" />
    <Meta name="keywords" :content="keywords" />
    <Meta
      v-if="isIos"
      name="viewport"
      content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
    />
  </Head>
  <LazyHeader class="max-w-full p-[16px]" />
  <section
    id="sectionContent"
    class="relative min-h-full max-w-full scrollbar-hide"
    :class="[cond1 ? 'twoColor' : '', rName ? rName : '']"
  >
    <div
      class="relative z-1 min-h-full"
      :class="[isDesktop ? 'xl:container mx-auto' : '']"
    >
      <slot />
    </div>
    <LazyModalHeadless
      :show="trx.isLoading"
      :line="false"
      :show-close="false"
      :close-outside="false"
      :class-content="'bg-transparent'"
    >
      <template #info>
        <div class="items-center text-center">
          <LazyIconsLoadingSpinner class="inline text-white" />
          <span class="ml-3 font-semibold text-flk-skyblue text-md"
            >Processing transaction...</span
          >
        </div>
      </template>
    </LazyModalHeadless>
  </section>
  <LazyFooter
    class="mb-[90px] sm:mb-[160px] md:mb-0 w-full xs:relative xs:z-0 md:fixed md:z-2 md:bottom-0"
  />
</template>
<style scoped>
.twoColor {
  background: linear-gradient(
    to right,
    white 0%,
    white 50%,
    #f9fafb 50%,
    #f9fafb 100%
  );
}
</style>
